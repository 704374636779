:root {
    --pa-color-primary: #0094BF;
    --pa-color-primary--light: #59c2de;
    --pa-color-secondary: #FBEC7A;
    --pa-color-secondary--dark: #ffce44;
    --pa-color-secondary--light: #ffffdc;
    --pa-color-grey-1: #707070;
    --pa-color-grey-2: #757575;
    --pa-color-dark: #676767;
    --pa-color-bright: #ff5722;
    --pa-color-green: #29cc29;

    --pa-color-text: #1c1c1c;

    --pa-font-base: 'Gotham Light', sans-serif;
    --pa-font-base--500: 'Gotham Medium', sans-serif;
    --pa-font-base--700: 'Gotham Bold', sans-serif;
    --pa-font-secondary: 'Pantamera Black', sans-serif;

    --pa-font-body: var(--pa-font-base);
    --pa-font-title: var(--pa-font-secondary);

    --pa-container-size: 1800px;
    --pa-container-size-small: 1295px;
    --pa-container-space-x: 2rem;

    --pa-icon-check: url(../images/check-icon.svg);
    --pa-icon-dot: url(../images/dot-icon.svg);
    --pa-icon-cart: url(../images/cart-icon.svg);
    --pa-icon-remove: url(../images/remove-icon.svg);
    --pa-icon-user: url(../images/user-icon.svg);
}

@media screen and (min-width: 2300px) {
    :root {
        --pa-container-size: 2000px;
        --pa-container-size-small: 1365px;
    }
}
