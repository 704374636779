main.page-main {
  min-height: 400px;
  padding-top: 3rem;
}
.container {
  @include bp-min($mq-lg) {
    max-width: calc(var(--pa-container-size) + (var(--pa-container-space-x)*2));
    padding-left: var(--pa-container-space-x);
    padding-right: var(--pa-container-space-x);
  }
}
.logo {
  .sidebar-opened & {
    z-index: 0;
  }
}