input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="time"],
input[type="week"],
textarea,
select {
    @include input-styles();
}
select {
    @include select-styles();
}
textarea {
    @include textarea-styles();
}
input[type="checkbox"] {
    @include checkbox-styles();
}
input[type="radio"] {
    @include radio-styles();
}

.fieldset {
    letter-spacing: normal;
    > .field,
    > .fields > .field {
        margin-bottom: 2rem;
        .label {
            font-family: var(--pa-font-base--500);
            font-size: 2rem;
            color: var(--pa-color-dark);
            margin-bottom: 1rem;
            display: block;
            padding-left: 0;
            line-height: 1.5;
        }
        &.required, &._required {
            > .label {
                &:after {
                    color: var(--pa-color-dark);
                    margin-left: .1rem;
                }
            }
        }
        .additional {
            margin-top: 1rem;
        }
        .action {
            @include underline-link-hover--reverse();
            color: var(--pa-color-primary);
            font-family: var(--pa-font-base--500);
        }
        &.choice {
            > input {
                display: none;
                &:checked {
                    + .label,
                    + label {
                        &:before {
                            background-color: #000;
                            border-color: #000;
                        }
                        &:after {
                            content: '';
                            display: inline-block;
                            transform: rotate(45deg);
                            height: 14px;
                            width: 9px;
                            border-bottom: 2px solid #fff;
                            border-right: 2px solid #fff;
                            position: absolute;
                            left: 10px;
                            top: 5px;
                        }
                    }
                }
            }
            .label, label {
                padding-left: 50px;
                position: relative;
                min-height: 30px;
                display: flex;
                align-items: center;
                cursor: pointer;
                &:before {
                    content: '';
                    width: 30px;
                    height: 30px;
                    border: 3px solid #676767;
                    background-color: #fffffa;
                    position: absolute;
                    left: 0;
                    top: 0;
                    transition: .2s background-color, border-color ease;
                }
            }
        }
        .password-strength-meter {
            font-size: 1.4rem;
        }
    }
    > .legend {
        font-family: var(--pa-font-base--500);
        font-size: 2rem;
        margin-bottom: 2rem;
        color: var(--pa-color-text);
        text-transform: none;
    }
    &.create {
        > .legend {
            font-family: var(--pa-font-base--500);
            font-size: 2.6rem;
            margin-bottom: 2rem;
            color: var(--pa-color-text);
        }
    }
}
div.mage-error,
.field-error {
    font-size: 1.4rem;
    color: var(--pa-color-bright);
}
.field-tooltip {
    > .label {
        @include sr-only();
    }
    .field-tooltip-content {
        &:before, &:after {
            left: -20px;
        }
        &:after {
            left: -19px;
        }
    }
}
.field {
    &._error {
        .control {
            input,
            select,
            textarea {
                border-color: var(--pa-color-bright);
                box-shadow: inset 0 0 0 2px var(--pa-color-bright) !important;
            }
        }
    }
    &.street {
        .field {
            > .label {
                @include sr-only();
            }
        }
    }
}