.page-header {
    &.type1 {
        .navigation {
            &:not(.side-megamenu) {
                .level0 {
                    .level-top {
                        color: var(--pa-color-text);
                        font-family: var(--pa-font-base--500);
                        transition: .3s color ease;
                        text-transform: none;
                        font-weight: normal;
                        @include bp-min($mq-lg) {
                            font-size: 1.4rem;
                            padding: 0;
                            letter-spacing: normal;
                        }
                        @include bp-range($mq-lg, $mq-xl) {
                            font-size: 1.2rem;
                        }
                        &:hover {
                            @include bp-min($mq-xl) {
                                color: var(--pa-color-primary);
                            }
                        }
                    }
                }
            }
        }
    }
    .navigation {
        padding: 0;
        &:not(.side-megamenu) {
            .level0 {
                margin-right: var(--pa-menu-space-x);
                &.parent {
                    &:hover {
                        @include bp-min($mq-lg) {
                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 100%;
                                left: 0;
                                width: 100%;
                                height: calc(100% + 3px);
                                z-index: 1;
                            }
                            > a {
                                color: var(--pa-color-primary);
                                > span {
                                    &:before {
                                        bottom: -11px;
                                    }
                                    &:after {
                                        bottom: -12px;
                                    }
                                }
                            }
                        }
                    }
                    > a {
                        &:after {
                            min-width: 9px;
                        }
                    }
                }
            }
        }
        a {
            @include bp-max($mq-lg) {
                color: var(--pa-color-text) !important;
            }
            &:hover {
                @include bp-max($mq-lg) {
                    color: var(--pa-color-text) !important;
                    text-transform: none;
                }
            }
        }
        li.level0 {
            .ui-menu-item {
                > a {
                    &:before {
                        @include bp-max($mq-lg) {
                            color: var(--pa-color-primary);
                        }
                    }
                }
            }
        }
    }
    .nav-toggle {
        color: var(--pa-color-text);
    }
}
.nav-sections {
    .header.links  {
        a, a:hover {
            @include bp-max($mq-lg) {
                color: var(--pa-color-text) !important;
                text-transform: none;
            }
        }
    }
    @include bp-max($mq-lg) {
        font-size: 1.4rem;
    }
}
.nav-open {
    .nav-sections {
        @include bp-max($mq-lg) {
            box-shadow: 0 0 12px 0 rgba(50, 50, 50, 0.3);
        }
    }
}

.sw-megamenu.navigation {
    .subchildmenu.mega-columns.columns4 {
        > li {
            &.level1 {

            }
        }
    }
    li.level0 {
        &.fullwidth {
            .submenu {
                @include bp-min($mq-lg) {
                    top: calc(100% + 10px);
                }
                li {
                    &.level1 {
                        > a {
                            @include bp-min($mq-lg) {
                                text-transform: none;
                                color: var(--pa-color-text);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 991px){
    ul#category-menu {
        position: absolute;
        background: #faeb7a;
        margin-top: 0px;
        width: 330px;
        border-radius: 25px;
        margin-left: -20px;
        padding-top: 20px;
    }

    .sw-megamenu.navigation li>a>span {
        position: relative;
        margin: 20px;
    }
}

@media screen and (max-width: 991px) {
    ul#category-menu {
        display: block !important;
    }

    .nav-open .nav-sections {
        background: #faeb7a;
    }

    .navigation .level0 {
        border: none !important;
    }

    .nav-sections .header.links li {
        border: none !important;
    }

    .navigation li.level0:last-child {
        padding-bottom: 20px;
    }

    .navigation li.level0>a.level-top {
        padding: 29px 36px 16px 19px;
        font-size: 14px !important;
    }

    header.page-header .nav-sections-item-title:not(.active) {
        display: none;
    }

    header.page-header .nav-sections-item-title {
        width: 100px;
    }

    .navigation {
        .header.links {
            > li {
                border-bottom: 2px solid white !important;
            }
        }
    }
}
