.minicart-wrapper {
  .block-minicart {
    @include bp-min($mq-sm) {
      min-width: 400px;
    }
    .total-count {
      text-transform: none;
      font-family: var(--pa-font-base--500);
      font-size: 1.4rem;
    }
    .subtotal {
      text-transform: none;
      font-family: var(--pa-font-base--500);
      font-size: 1.6rem;
    }
    .product {
      .actions {
        > .secondary {
          box-shadow: none;
          background: none;
          border-radius: 0;
        }
      }
    }
  }
  li {
    > .product {
      width: 100%;
    }
  }
}
.minicart-items-wrapper {
  @include custom_scrollbar(5px, 5px, #e4e4e4, #b2b2b2, #fff, 0px);
  padding-right: 1rem;
}