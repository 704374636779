.footer-middle {
    --footer-column-space-x: #{"min(5vw, 85px)"};
    background-color: var(--pa-color-primary);
    color: #fff;
    @include bp-max($mq-xxl) {
        --footer-column-space-x: #{"min(3.1vw, 75px)"};
    }
    > .container {
        padding-top: 3rem;
        padding-bottom: 3rem;
        &:after {
            display: none;
        }
    }
    a.pa-btn {
        color: var(--pa-color-text) !important;
    }
    .column-1 {
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        @include bp-min($mq-lg) {
            border-right: 1px solid #fff;
        }
        img {
            margin: 0 auto 2rem;
            max-height: 28px;
            display: block;
        }
        .pa-btn {
            margin-top: 2rem;
            width: 100%;
            max-width: 320px;
        }
        .inner {
            @include bp-min($mq-lg) {
                padding-right: var(--footer-column-space-x);
            }
        }
    }
    .column {
        &:not(.column-1)  {
            @include bp-min($mq-lg) {
                padding: 2rem 0 2rem var(--footer-column-space-x);
            }
        }
    }
    ul {
        &:not(.social) {
            li {
                padding: .3rem 0;
            }
            a {
                @include underline-link-hover();
                color: #fff;
                &:visited {
                    color: #fff;
                }
            }
        }
    }
}
.footer-bottom {
    background-color: var(--pa-color-primary--light);
    color: var(--pa-color-text);
    padding: 1.9rem 0;
    font-size: 1.2rem;
}
.footer-ribbon {
    background: none;
    &:before {
        display: none;
    }
}
.page-footer {
    --pa-container-size: 1700px;
    .logo {
        margin: 0 auto 2rem;
        max-height: 28px;
        display: block;
        object-fit: contain;
        max-width: 320px;
    }
    .social {
        display: flex;
        padding-top: 2rem;
        li {
            &:not(:last-child) {
                margin-right: 3rem;
            }
        }
        a {
            color: var(--pa-color-secondary);
            @include bp-min($mq-xl) {
                transition-duration: .3s;
                transition-property: color;
                transition-timing-function: ease;
            }
            &:hover {
                @include bp-min($mq-xl) {
                    color: var(--pa-color-text);
                }
            }
        }
    }
    .payment {
        font-size: 1.2rem;
        > div {
            max-width: 28rem;
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            padding: 5rem 0;
            @include bp-min($mq-lg) {
                margin-left: auto;
            }
        }
        hr {
            background: #fff;
            width: 100%;
        }
        img {
            object-fit: contain;
            margin-right: 2rem;
            max-height: 40px;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}