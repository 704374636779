h1 {
  font-size: 3rem;
  font-family: var(--pa-font-title);
}

.page-main {
  > .page-title-wrapper {
    .page-title {
      font-size: 4.6rem;
      font-family: var(--pa-font-base--700);
      color: var(--pa-color-primary);
      margin-bottom: 2rem;
      @include bp-max($mq-sm) {
        font-size: 3.6rem;
      }
    }
  }
}