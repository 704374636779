.pa-btn {
  @include button-styles();
  &.secondary {
    @include button-styles--secondary();
  }
  &.light {
    @include button-styles--light();
  }
  &.primary {
    @include button-styles--primary();
    &.light {
      border-color: var(--pa-color-primary);
      color: var(--pa-color-primary);
      &:hover {
        @include bp-min($mq-xl) {
          background-color: var(--pa-color-primary);
          color: #fff;
        }
      }
    }
  }
}

div.pagebuilder-button-primary,
div.pagebuilder-button-secondary,
a.pagebuilder-button-primary,
a.pagebuilder-button-secondary,
button.pagebuilder-button-primary,
button.pagebuilder-button-secondary {
  @include button-styles();
}
div.pagebuilder-button-primary,
a.pagebuilder-button-primary,
button.pagebuilder-button-primary {
  @include button-styles--primary();
  &:hover {
    background-color: transparent;
    border-width: 2px;
  }
  .light > & {
    @include button-styles--light();
  }
}
div.pagebuilder-button-secondary,
a.pagebuilder-button-secondary,
button.pagebuilder-button-secondary {
  @include button-styles--secondary();
  &:hover {
    background-color: transparent;
    border-width: 2px;
  }
  .light > & {
    @include button-styles--light();
  }
}
div.pagebuilder-button-link,
a.pagebuilder-button-link,
button.pagebuilder-button-link {
  @include underline-link-hover--reverse();
  padding: 0;
  font-size: 1.8rem;
  color: var(--pa-color-primary);
  font-family: var(--pa-font-base--500);
  &:hover, &:visited {
    color: var(--pa-color-primary);
  }
}
.action {
  &.primary {
    @include button-styles();
    @include button-styles--primary();
    text-transform: none;
    &.light {
      @include button-styles--light();
    }
    &:hover, &:active, &:focus {
      border-width: 2px;
    }
  }
}
a.action.primary {
  display: inline-flex;
  .actions-toolbar & {
    display: inline-flex;
  }
}