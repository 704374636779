header.page-header {
    border: 0;
    box-shadow: -3.0616169991140216e-16px 5px 15px rgba(0,0,0,0.15);
    .sidebar-opened & {
        //z-index: 1;
    }
    .panel.wrapper {
        min-height: unset;
    }
    .header.content {
        display: flex;
        align-items: center;
        @include bp-min($mq-lg) {
            max-width: calc(var(--pa-container-size) + (var(--pa-container-space-x)*2));
            padding-left: var(--pa-container-space-x);
            padding-right: var(--pa-container-space-x);
        }
    }
    .logo {
        min-width: 150px;
        margin-right: 1rem;
        @include bp-max($mq-xxl) {
            max-width: 200px;
        }
        @include bp-max($mq-lg) {
            max-width: 150px;
        }
    }
    &.sticky-header {
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        .main-panel-top {
            display: none;
        }
        @include bp-min($mq-lg) {
            .minicart-wrapper {
                position: static !important;
                transform: none !important;
            }
            .nav-sections {
                width: auto !important;
                background: none !important;
                position: static !important;
                box-shadow: none !important;
            }
        }
        @include bp-max($mq-lg) {
            .header-main {
                position: static;
                box-shadow: none;
                background-color: var(--pa-color-secondary);
            }
        }
    }
}
.main-panel-top {
    background-color: var(--pa-color-primary);
    padding: .9rem 0;
}
.header-message {
    color: #fff;
    text-align: center;
    font-family: var(--pa-font-base--500);
    font-size: 2rem;
    width: 90%;
    font-weight: normal;
    @include bp-max($mq-xl) {
        font-size: 1.6rem;
    }
    @include bp-max($mq-md) {
        font-size: 1.4rem;
    }
    p {
        margin: 0;
        line-height: 1.2;
    }
}
.header-left {
    color: #fff;
    text-align: center;
    font-family: var(--pa-font-base--500);
    font-size: 2rem;
    font-weight: normal;
    width: 10%;
    @include bp-max($mq-xl) {
        font-size: 1.6rem;
    }
    @include bp-max($mq-md) {
        font-size: 1.4rem;
    }
}
.header.content {
    display: flex;
    align-items: center;
    .minicart-wrapper {
        order: 5;
        margin-left: 2rem;
        @include bp-max($mq-lg) {
            margin-left: 1rem;
        }
    }
}
.header-nav {
    &--group {
        display: flex;
        align-items: center;
        margin-top: 1.5rem;
        @include bp-max($mq-xxl) {
            margin-top: 1rem;
        }
        @include bp-min($mq-lg) {
            flex-shrink: 0;
            --pa-menu-space-x: #{"min(3vw, 20px)"};
        }
        @include bp-min($mq-xl) {
            --pa-menu-space-x: #{"min(3.5vw, 40px)"};
        }
        @include bp-min($mq-xxl) {
            --pa-menu-space-x: #{"min(5vw, 77px)"};
        }
    }
}
.panel.header {
    > .header.links {
        @include bp-min($mq-lg) {
            display: flex;
            align-items: center;
        }
        > li {
            &:not(.custom-link):last-child {
                display: none;
            }
            > a {
                color: var(--pa-color-text);
                padding: 0;
                transition: .3s color ease;
                line-height: 1.2;
                text-transform: none;
                @include bp-min($mq-lg) {
                    font-size: 1.4rem;
                }
                @include bp-range($mq-lg, $mq-xl) {
                    font-size: 1.2rem;
                }
                &:hover {
                    @include bp-min($mq-xl) {
                        color: var(--pa-color-primary);
                    }
                }
            }
            &.greet.welcome {
                display: none;
            }
            &.customer-welcome {
                @include bp-min($mq-lg) {
                    display: none;
                }
            }
        }
        .authorization-link {
            display: none;
        }
    }
}
.header.links {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        margin: 0 var(--pa-menu-space-x) 0 0;
        text-transform: none;
        &:not(.contact-link):nth-last-of-type(2) {
            //margin-right: 0;
        }
        a {
            font-family: var(--pa-font-base--500);
            color: var(--pa-color-text);
        }
    }
}
.header-main {
    background-color: var(--pa-color-secondary);
    padding: 1.775rem 0;
    @include bp-max($mq-lg) {
        padding: .775rem 0;
    }
    .header-left,
    .header-center,
    .header-right {
        padding: 0;
    }
}
.header-contact {
    a {
        color: var(--pa-color-text);
        .svg-icon {
            vertical-align: middle;
            @include bp-max($mq-sm) {
                width: 30px;
                height: 30px;
            }
        }
    }
}
.header-row {
    .header-center {
        @include bp-max($mq-lg) {
            margin: 0 0 0 auto;
            flex: none;
        }
    }
    .header-right {
        @include bp-max($mq-lg) {
            margin-left: 0;
        }
    }
}

header.page-header  {
    .nav-sections-item-title {
        @include bp-max($mq-lg) {
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 0;
            height: 5rem;
            font-family: var(--pa-font-base--500);
            font-weight: normal;
        }
        &:not(.active) {
            background-color: var(--pa-color-primary);
        }
        &.active {
            > a, > a:hover, > a:focus {
                @include bp-max($mq-lg) {
                    color: var(--pa-color-primary);
                }
            }
        }
    }
}

.page-header .navigation a, .panel.header>.header.links a {
    font-size: 1.7rem!important;
    font-family: var(--pa-font-base--700)!important;
}
