.breadcrumbs {

}
.page-wrapper {
  > .breadcrumbs {
    background: none;
    color: var(--pa-color-text);
    font-size: 1.2rem;
    .items {
      max-width: calc(var(--pa-container-size-small) + (var(--pa-container-space-x)*2));
      padding: 3.5rem var(--pa-container-space-x) 3rem;
      > li {
        text-transform: none;
        &:not(:last-child) {
          &:after {
            width: 16px;
          }
        }
        &.home {
          a {
            width: auto;
            text-indent: 0;
            &:after {
              display: none;
            }
          }
        }
        a {
          color: var(--pa-color-grey-2);
          &:hover {
            color: var(--pa-color-primary);
          }
        }
        strong {
          font-family: var(--pa-font-body);
        }
      }
    }
  }
}