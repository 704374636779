html {
    font-size: 62.5%;
}
body {
    font-size: 1.6rem;
    font-family: var(--pa-font-body);
    color: var(--pa-color-text);
}
strong, b {
    font-family: var(--pa-font-base--500);
}
a {
    color: var(--pa-color-primary);
    transition: .2s color ease;
    &:visited {
        color: var(--pa-color-primary);
    }
}
.svg-icon {
    fill: currentColor;
}
[data-content-type='html'] {
    word-wrap: normal;
}

/* Cookie Bot */
#CybotCookiebotDialog {
    $blue: #0094bf;
    $lightblue: #eff6ff;
    $darkblue: #113266;
    $lightGrey: #9e9c9c;
    $offwhite: #ffffdc;

    $md: 48rem;
    $lg: 62rem;

    font-family: Gotham, Arial, serif !important;
    border-radius: 0 !important;
    background-color: $offwhite !important;

    .CybotCookiebotDialogContentWrapper {
        .CybotCookiebotScrollContainer {
            border-bottom: none;
            padding: 0;
            color: $darkblue;
        }

        /* Logotypes */
        #CybotCookiebotDialogHeader {
            display: none;
        }

        /* Tabs */
        #CybotCookiebotDialogNav {
            border-bottom: 1px solid $lightblue;
            @media(min-width: $lg) {
                padding: 1rem 2.5rem 0;
            }
            ul {
                li {
                    a {
                        color: $darkblue;
                        &:hover {
                            color: $blue;
                        }
                        &.CybotCookiebotDialogActive {
                            color: $darkblue;
                            border-bottom-color: $darkblue;
                        }
                    }
                }
            }
        }

        /* Dialog body text */
        #CybotCookiebotDialogTabContent {
            .CybotCookiebotDialogBodyLevelButtonSliderWrapper {
                input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
                    background-color: $darkblue;
                }
                .CybotCookiebotDialogBodyLevelButtonSlider {
                    background-color: $lightGrey;
                    &:before {
                        background-color: $lightblue;
                    }
                }
            }

            #CybotCookiebotDialogBody {
                color: $darkblue;
                .CybotCookiebotScrollContainer {
                    max-height: none;
                    .CybotCookiebotScrollArea {
                        scrollbar-color: $darkblue $lightGrey !important;
                        @media (min-width: $lg) {
                            padding: 0 2.5rem;
                        }
                        h2 {
                            font-family: Pantamera, Arial;
                            font-size: 1.5rem;
                            @media (min-width: $md) {
                                font-size: 2.25rem;
                            }
                        }
                    }
                }
                .CybotCookiebotDialogBodyBottomWrapper {
                    #CybotCookiebotDialogBodyLevelButtonsSelectPane {
                        border-bottom: none;
                        padding: 0;
                        .CybotCookiebotDialogBodyLevelButtonWrapper {
                            border-right: none;
                            label {
                                strong {
                                    &:hover {
                                        color: $blue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            #CybotCookiebotDialogDetailBody {
                #CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
                    .CookieCard {
                        color: $darkblue;

                        button {
                            &:hover {
                                color: $blue;
                            }
                        }
                    }
                }
            }
        }

        /* Buttons */
        #CybotCookiebotDialogFooter {
            #CybotCookiebotDialogBodyButtonsWrapper {
                button {
                    border-color: $darkblue;
                    color: $darkblue;
                    border-radius: 0;
                    background-color: $lightblue;
                }
            }
        }
    }

    /* Link */
    #CybotCookiebotDialogBodyContentText a,
    #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink,
    #CybotCookiebotDialogDetailBulkConsentList dt a,
    #CybotCookiebotDialogDetailFooter a,
    .CybotCookiebotDialogBodyLevelButtonIABDescription a,
    .CybotCookiebotDialogDetailBodyContentCookieLink,
    #CybotCookiebotDialogDetailBodyContentTextAbout a,
    .CookieCard a {
        color: $blue !important;
    }
}
.CybotCookiebotDialogActive + #CybotCookiebotDialogBodyUnderlay {
    opacity: 0.8 !important;
}
.CybotCookiebotFader {
    display: none;
}
.cookieconsent-optout-marketing {
    background: #fffffa;
    font-family: 'Gotham', Arial, serif;
    border-radius: 0.9375rem;
    text-align: center;
    width: 100%;
    margin-top: 3rem;
    a {
        outline: none;
        position: relative;
        font-weight: 700;
        color: #0094bf;
        text-decoration: none;
        margin-right: 0.3rem;
    }
}
