.header {
    .block-search {
        @include bp-min($mq-md) {
            min-width: 2.6rem;
        }
        .control {
            margin: 0 -5px 0;
            padding: 0;
        }
        .field {
            input {
                border-radius: 0;
                background: none;
                padding: 0;
                font-size: 1.4rem;
                outline: none;
                box-shadow: none;
                height: 3rem;
                border: 0;
                @include bp-min($mq-lg) {
                    border-bottom: 1px solid var(--pa-color-grey-1);
                }
                &::placeholder {
                    color: var(--pa-color-grey-1);
                    opacity: 1;
                }
                &:focus {
                    outline: none;
                    box-shadow: none !important;
                    @include bp-min($mq-lg) {
                        box-shadow: inset 0 -2px 0 0 var(--pa-color-primary) !important;
                        border-bottom-color: var(--pa-color-primary);
                    }
                }
            }
            &.search {
                margin-left: 3rem;
                @include bp-max($mq-lg) {
                    margin-left: 4rem;
                }
            }
        }
        .form {
            .action.search {
                color: #000;
                left: 0;
                right: auto !important;
                height: 3rem;
                line-height: 3rem;
                width: auto !important;
                @include bp-max($mq-lg) {
                    left: 10px;
                    position: absolute;
                    top: 0;
                }
                &:before {
                    display: none;
                }
                &[disabled] {
                    opacity: 1;
                }
                .svg-icon {
                    vertical-align: bottom;
                    @include bp-max($mq-lg) {
                        vertical-align: middle;
                        width: 16px;
                        height: 16px;
                        margin-bottom: 3px;
                    }
                }
            }
        }
    }
    .search-area {
        width: auto;
        @include bp-min($mq-lg) {
            flex-shrink: 1;
            flex-basis: 240px;
            margin-left: #{"min(3vw, 40px)"};
        }
        @include bp-min($mq-xxl) {
            margin-left: #{"min(6vw, 120px)"};
        }
        .block.block-search {
            background: none;
            border-radius: 0;
            @include bp-max($mq-lg) {
                border-width: 3px;
                background: #fff;
                right: -10px;
            }
            @include bp-min($mq-lg) {
                width: unset;
                min-width: unset;
            }
            @include bp-max($mq-sm) {
                right: -8rem;
                min-width: calc(100vw - 4rem);
            }
        }
        .search-toggle-icon {
            color: var(--pa-color-text);
            .svg-icon {
                vertical-align: middle;
                @include bp-max($mq-sm) {
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .input-text {
            @include bp-min($mq-lg) {
                width: 100%;
            }
        }
        #search_mini_form {
            @include bp-min($mq-lg) {
                width: unset;
                min-width: unset;
            }
        }
    }
}
