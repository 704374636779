.ln_overlay {
  .loader {
    border-top-color: var(--pa-color-primary);
    animation: spin 2s linear infinite;
  }
}

.loading-mask {
  background: rgba(255, 255, 255, 0.5);
  .loader {
    border: 2px solid transparent;
    border-top-color: var(--pa-color-primary);
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    display: inline-block;
    border-image: none;
    border-radius: 50%;
    vertical-align: middle;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    &:before {
      content: '';
      position: absolute;
      left: -2px;
      top: -2px;
      width: inherit;
      height: inherit;
      border: inherit;
      border-radius: inherit;
      border-top-color: inherit;
      animation: spin 1.5s infinite;
      margin: 0;
    }
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}