.modal-slide,
.modal-popup {
  .modal-inner-wrap {
    box-shadow: 0 0 12px 12px rgba(0, 0, 0, 0.1);
  }
}
.modal-popup {
  &.modal-slide {
    .modal-inner-wrap {
      overflow-x: hidden;
    }
  }
  .modal-header {
    padding-top: 2rem;
    padding-bottom: 1.5rem;
  }
  .modal-title {
    font-size: 2.8rem;
    font-family: var(--pa-font-base--500);
    text-transform: none;
    color: var(--pa-color-text);
    border-bottom-color: #000;
    margin-bottom: 2.5rem;
  }
  .modal-footer {
    padding-top: 2rem;
    padding-bottom: 2.5rem;
  }
  .action-secondary {
    @include button-styles();
    @include button-styles--light();
  }
  .action-primary {
    @include button-styles();
    @include button-styles--primary();
  }
  .action-secondary,
  .action-primary,
  .action.primary {
    min-height: 45px;
    font-size: 1.4rem;
    margin: .5rem .5rem .5rem 0;
    @include bp-max($mq-sm) {
      width: 100%;
      margin-right: 0;
    }
  }
}
.modal-custom {
  &.authentication-dropdown {
    border: 0;
    box-shadow: 0 7px 20px rgba(0,0,0,0.12);
    max-width: 570px;
    right: 0;
    .block-title {
      font-size: 2.6rem;
    }
    .modal-inner-wrap {
      padding: 2.5rem 3rem;
      overflow-x: hidden;
    }
    .actions-toolbar {
      padding-top: 0;
    }
    .action.action-login {
      @include button-styles();
      @include button-styles--primary();
      min-height: 45px;
    }
    .action.action-remind {
      > span {
        @include underline-link-hover--reverse();
        display: inline;
        font-family: var(--pa-font-base--500);
      }
    }
  }
}