@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}
/* Mixin for adaptive font size:

Example: @include fluid-type($min_width, $max_width, $min_font, $max_font);

*/
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size, $rule:false) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      @if $rule {
        font-size: $min-font-size $rule;
        @media screen and (min-width: $min-vw) {
          font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})) $rule;
        }
        @media screen and (min-width: $max-vw) {
          font-size: $max-font-size $rule;
        }
      } @else {
        font-size: $min-font-size;
        @media screen and (min-width: $min-vw) {
          font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
        }
        @media screen and (min-width: $max-vw) {
          font-size: $max-font-size;
        }
      }
    }
  }
}

/* Mixin for custom scroll bar:

Example: @include custom_scrollbar(5px, 5px, #e4e4e4, #b2b2b2, #fff, 20px);

*/

@mixin custom_scrollbar($size, $radius, $track_bg, $thumb_bg, $wrap_bg, $offset) {
  -ms-scrollbar-base-color: $thumb_bg;
  scrollbar-color: rgba($thumb_bg, .5) $track_bg;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    height: $size;
    width: $size;
  }
  &::-webkit-scrollbar-track {
    background-color: $track_bg;
    box-shadow: inset $offset 0px 0px 1.5px $wrap_bg;
  }
  &::-webkit-scrollbar-thumb {
    height: $size;
    width: $size;
    background-color: $thumb_bg;
    border-radius: $radius;
  }
}

@mixin button-styles() {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1;
  padding: 5px 2rem;
  min-height: 54px;
  min-width: 150px;
  text-decoration: none !important;
  border: 2px solid transparent;
  outline: none;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border-radius: 30px;
  font-family: var(--pa-font-base--500);
  font-size: 1.6rem;
  color: var(--pa-color-text);
  background-color: var(--pa-color-grey-1);
  text-transform: none;
  @include bp-min($mq-xl) {
    transition-duration: .3s;
    transition-property: border-color, color, background-color;
    transition-timing-function: ease;
  }
}
@mixin button-styles--secondary() {
  background-color: var(--pa-color-secondary);
  &:hover {
    @include bp-min($mq-xl) {
      background-color: transparent;
      border-color: currentColor;
      color: var(--pa-color-text);
    }
  }
}
@mixin button-styles--primary() {
  border-color: var(--pa-color-primary);
  background-color: var(--pa-color-primary);
  color: #fff;
  &:visited {
    color: #fff;
  }
  &:hover {
    @include bp-min($mq-xl) {
      background-color: transparent;
      border-color: currentColor;
      color: var(--pa-color-primary);
    }
  }
}
@mixin button-styles--large() {
  min-height: 70px;
  font-size: 2.4rem;
  text-transform: none;
  border-radius: 40px;
  padding: 5px 4rem;
}

@mixin button-styles--light() {
  background-color: transparent;
  border-color: var(--pa-color-text);
  color: var(--pa-color-text);
  &:hover {
    @include bp-min($mq-xl) {
      border-color: var(--pa-color-primary);
      background-color: var(--pa-color-primary);
      color: #fff;
    }
  }
}

@mixin button-styles--add-cart() {
  @include button-styles();
  @include button-styles--secondary();
  &:before {
    display: none;
  }
  &:after {
    content: '';
    display: inline-block;
    mask-image: var(--pa-icon-cart);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
    width: 17px;
    height: 12px;
    margin-left: 7px;
    background-color: currentColor;
  }
}

@mixin input-styles() {
  font-family: var(--pa-font-body);
  height: 60px;
  border-radius: 0;
  border: 1px solid var(--pa-color-dark);
  background-color: #fff;
  font-size: 1.8rem;
  padding: 5px 2rem;
  width: 100%;
  color: var(--pa-color-text);
  transition-duration: .2s;
  transition-property: border-color, color, box-shadow;
  transition-timing-function: ease;
  &:focus {
    border-color: var(--pa-color-primary);
    box-shadow: inset 0 0 0 2px var(--pa-color-primary) !important;
  }
  &.mage-error {
    border-color: var(--pa-color-bright);
    box-shadow: inset 0 0 0 2px var(--pa-color-bright) !important;
  }
}

@mixin select-styles() {
  --arrow-position: 15px;
  --arrow-size: 28px;
  appearance: none;
  text-indent: 1px;
  text-overflow: ellipsis;
  padding-right: 50px;
  background-repeat: no-repeat;
  background-position: calc(100% - var(--arrow-position)) center;
  background-size: var(--arrow-size) var(--arrow-size);
  background-image: url("../images/arrow-down.svg");
}
@mixin textarea-styles() {
  height: auto;
  min-height: 150px;
  padding-top: 2rem;
  padding-bottom: 2rem;
  &:focus {
    box-shadow: none;
  }
}
@mixin checkbox-styles() {
  appearance: none;
  width: 30px;
  height: 30px;
  border: 3px solid #676767;
  background-color: #fffffa;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 12px;
  padding: 0;
  margin: 0;

  transition: .2s background-color, border-color ease;
  &:focus {
    box-shadow: none;
  }
  &:checked {
    background-color: #000;
    border-color: #000;
    background-image: var(--pa-icon-check);
  }
}
@mixin radio-styles() {
  appearance: none;
  width: 25px;
  height: 25px;
  border: 2px solid #000;
  background-color: #fffffa;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px 15px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  transition: .2s background-color, border-color ease;
  &:focus {
    box-shadow: none;
  }
  &:checked {
    background-image: var(--pa-icon-dot);
  }
}
@mixin container-styles() {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}
@mixin row($space) {
  --bs-gutter-x: #{$space};
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
@mixin col($space, $width, $media) {
  flex: 0 0 auto;
  flex-shrink: 0;
  max-width: 100%;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  @include bp-min('#{$media}') {
    width: $width;
  }
}

@mixin sr-only() {
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 0 !important;
  left: 0;
  top: 0;
}

@mixin container() {
  --bs-gutter-x: 2.5rem;
  max-width: calc(1545px + (var(--bs-gutter-x) * .5) * 2);
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@mixin container-large() {
    max-width: calc(1920px + (var(--bs-gutter-x) * .5) * 2);
}

@mixin underline-link-hover() {
    text-decoration: none !important;
    @include bp-min($mq-xl) {
        background: linear-gradient(0deg, currentColor, currentColor) no-repeat right bottom / 0 var(--bg-h);
        transition: background-size 350ms;
        --bg-h: 1px;
        padding-bottom: 1px;
        &:where(:hover, :focus-visible) {
          background-size: 100% var(--bg-h);
          background-position-x: left;
        }
    }
}
@mixin underline-link-hover--reverse() {
  text-decoration: none !important;
  --bg-h: 1px;
  background: linear-gradient(0deg, currentColor, currentColor) no-repeat right bottom / 0 var(--bg-h);
  padding-bottom: 1px;
  background-size: 100% var(--bg-h);
  @include bp-min($mq-xl) {
    transition: background-size 350ms;
    &:where(:hover, :focus-visible) {
      background-size: 0 var(--bg-h);
      background-position-x: right;
      color: currentColor;
    }
  }
}
