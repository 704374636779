@font-face {
    font-family: 'Gotham Medium';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('Gotham Medium'), url(../fonts/gotham/gotham-medium.woff2) format('woff2');
}

@font-face {
    font-family: 'Gotham Light';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('Gotham Light'), url(../fonts/gotham/gotham-light.woff2) format('woff2');
}

@font-face {
    font-family: 'Gotham Bold';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('Gotham Bold'), url(../fonts/gotham/gotham-bold.woff2) format('woff2');
}

@font-face {
    font-family: 'Pantamera Black';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('Pantamera Black'), url(../fonts/pantamera/pantamera-black.woff2) format('woff2');
}
