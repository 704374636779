.table-wrapper {
  @include bp-range($mq-sm-large, $mq-lg) {
    overflow-y: hidden;
    overflow-x: auto;
  }
  @include bp-max($mq-sm-large) {
    border-top: 0;
  }
  .table:not(.totals):not(.cart):not(.table-comparison) {
    > tbody {
      > tr {
        td[data-th]{
          &:before {
            @include bp-max($mq-sm-large) {
              font-family: var(--pa-font-base--500);
              color: var(--pa-color-text);
            }
          }
        }
        td {
          @include bp-max($mq-sm-large) {
            padding-bottom: 2rem;
            &:first-child {
              padding-top: 2.5rem;
            }
            &:last-child {
              padding-bottom: 2.5rem;
            }
          }
        }
      }
    }
  }
}
.table {
  > caption {
    @include sr-only();
  }
  thead {
    th.col {
      font-family: var(--pa-font-base--500);
      color: var(--pa-color-text);
      text-transform: none;
      font-size: 1.4rem;
      border-bottom: 2px solid rgba(0, 0, 0, .29);
      line-height: 1;
      @include bp-range($mq-sm-large, $mq-lg) {
        white-space: nowrap;
      }
      &:first-child {
        padding-left: 0;
      }
      @include bp-min($mq-large) {
        font-size: 1.6rem;
      }
    }
  }
  tbody {
    td.col {
      font-size: 1.4rem;
      border-bottom: 2px solid rgba(0, 0, 0, .1);
      vertical-align: middle;
      line-height: 1.2;
      position: relative;
      @include bp-min($mq-sm-large) {
        height: 10rem;
      }
      &:first-child {
        padding-left: 0;
      }
      &.actions {
        > a {
          color: var(--pa-color-text);
          transition: .2s opacity ease;
          margin: 0 .5rem;
          display: inline-block;
          &:after {
            display: none !important;
          }
          &:hover {
            opacity: .5;
          }
          span {
            @include sr-only();
          }
          &.edit {
            &:before {
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              font-size: 18px;
              min-width: 14px;
              line-height: 20px;
              color: currentColor;
              content: '\f826';
              font-family: 'porto-icons';
              vertical-align: middle;
              display: inline-block;
              font-weight: normal;
              overflow: hidden;
              speak: none;
              text-align: center;
              margin-right: 5px;
            }
          }
          &.delete {
            color: var(--pa-color-text) !important;
            &:before {
              content: '';
              display: inline-block;
              vertical-align: middle;
              width: 16px;
              height: 19px;
              mask-image: url("../images/remove-icon.svg");
              mask-repeat: no-repeat;
              mask-size: contain;
              background: currentColor;
            }
          }
        }
      }
      @include bp-min($mq-large) {
        font-size: 1.6rem;
      }
    }
  }
}