.sidebar-opened {
  overflow: hidden;
}
.sidebar {
  .sidebar-content {
    .block-category-list {
      padding: 0;
      border: 0;
    }
  }
}
