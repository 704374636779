#totop {
  background-color: var(--pa-color-primary);
  width: 45px;
  transition: .2s transform ease;
  &:hover {
    background-color: var(--pa-color-primary);
    transform: translateY(-10px);
  }
  .svg-icon {
    vertical-align: middle;
  }
}