.minicart-wrapper {
    .action-subtotal {
        @include sr-only();
    }
    .action.showcart {
        color: var(--pa-color-text);
        .svg-icon {
            @include bp-max($mq-sm) {
                width: 28px;
                height: 21px;
            }
        }
        &:after {
            min-width: 11px;
            display: none;
        }
        .counter.qty {
            background-color: var(--pa-color-primary);
            right: -8px;
            top: -12px;
            font-size: 1.1rem;
        }
    }
    .block-minicart {
        box-shadow: 0 7px 20px rgba(0, 0, 0, .12);
        font-size: 1.2rem;
        .total-count {
            .actions {
                a {
                    opacity: .5;
                    color: #1c1c1c;
                    transition: .2s color, opacity ease;
                    &:hover {
                        opacity: 1;
                        color: var(--pa-color-primary);
                    }
                }
            }
        }
    }
}
.block-minicart {
    .block-content {
        > .actions {
            > .primary {
                .action.primary {
                    @include button-styles();
                    @include button-styles--primary();
                }
            }
        }
    }
}
.minicart-items {
    .product-item-details {
        > *:not(.product-item-name) {
            font-size: 1.2rem;
        }
        .price,
        .price-including-tax,
        .price-excluding-tax {
            font-size: 1.2rem;
        }
    }
    .product-item-name {
        font-size: 1.6rem;
        a {
            &:hover {
                color: var(--pa-color-primary) !important;
            }
        }
    }
    .product-item-pricing {
        .label {
            font-family: var(--pa-font-base--500);
            color: var(--pa-color-text);
            width: auto;
            margin-right: 1rem;
        }
    }
    .item-qty {
        height: 28px;
        width: 42px;
        border-radius: 6px;
    }
}