@mixin bp-max($point){
    @media screen and (max-width: #{$point - 1}) {@content;}
}
@mixin bp-min($point){
    @media screen and (min-width: #{$point}) {@content;}
}
@mixin bp-range($min, $max) {
    @media screen and (min-width: $min) and (max-width: $max - 1) {@content;}
}
@mixin bp-viewport-h {
    @media screen and (min-width: $mq-lg) and (max-width: $mq-xxl + 1) {
        @content;
    }
}
@mixin retina {
    @media
    only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: $retina-width),
    only screen and (min-resolution: 192dpi) and (min-width: $retina-width)
        /* Retina-specific stuff here */
    {@content}
}

$mq-sm: 576px;
$mq-sm-large: 640px;
$mq-md: 769px;
$mq-lg: 992px;
$mq-xl: 1200px;
$mq-xxl: 1400px;
$mq-large: 1700px;
$mq-large-xl: 2000px;

$retina-width: 2300px;
